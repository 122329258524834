<template>
  <v-card width="90%" style="margin: 15px auto;" v-if="proc">
    <CommentDialog
      v-if="commentDialogShow"
      :title="commentDialogTitle"
      :fieldName="commentDialogFieldName"
      :processTmpl="proc"
      :lastComment="commentDialogLastComment"
      v-on:create:comment="createComment"
      v-on:update:comment="updateComment"
      v-on:delete:comment="deleteComment"
      v-on:close="commentDialogShow = false"
      v-on:commentUpdate="highligthFields"
    />
    <slot name="title" :proc="proc"/>
    <v-card-text>
      <TmplProcessView
        class="hovered"
        :process="proc"
        v-on:click="openCommentDialog"
      />
    </v-card-text>
    <slot name="actions" :proc="proc"/>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import CommentDialog from './CommentDialog.vue'
import TmplProcessView from '../../process-template/components/ViewTmpl.vue'

export default {
  components: { CommentDialog, TmplProcessView },
  data: () => ({
    proc: null,
    commentDialogShow: false,
    commentDialogTitle: '',
    commentDialogFieldName: '',
    commentDialogLastComment: null,
  }),
  computed: {
    userId() { return this.$store.state.user.profile.userId },
  },
  methods: {
    createComment(comment) {
      this.proc.comments.push(Object.assign({}, comment))
      this.highligthFields()
      this.commentDialogShow = false
    },
    updateComment(comment) {
      let com = this.proc.comments.find(c => {
        return c.id === comment.id
      })
      if (com) {
        com.message = comment.message
      } else {
        alert('Fatal error, please reload page')
      }
      this.highligthFields()
      this.commentDialogShow = false
    },
    deleteComment(comment) {
      alert('delete not work')
      this.highligthFields()
      this.commentDialogShow = false
    },
    sortProcComments() {
      this.proc.comments.sort((a, b) => {
        if (a.fieldName == b.fieldName) {
          return b.createdAt - a.createdAt
        } else if (a.fieldName > b.fieldName) {
          return 1
        }
        return -1
      })
    },
    highligthFields() {
      this.sortProcComments()
      var fn = ''
      this.proc.comments.forEach(c => {
        if (c.fieldName != fn) {
          fn = c.fieldName
          let el = document.getElementById(fn)
          if (el) {
            switch(c.status) {
              case 1:
                el.style.backgroundColor = 'red'
                break
              case 2:
                el.style.backgroundColor = 'green'
                break
              case 3:
                el.style.backgroundColor = 'orange'
                break
              case 4:
                el.style.backgroundColor = 'blue'
                break
            }
          } else {
            console.log(fn,'not found in DOM')
          }
        }
      })
    },
    openCommentDialog(procField) {
      if(this.proc.status == 3 || this.proc.status == 8) {
        // proc in correct
        if (!this.proc.comments.find(c => c.fieldName == procField.fieldName)) {
          return
        }
      }
      this.commentDialogTitle = procField.fieldTitle
      this.commentDialogFieldName = procField.fieldName
      this.commentDialogLastComment = this.proc.comments.find(c => {
        return (c.fieldName == procField.fieldName && c.status === 4)
      })
      if (this.commentDialogLastComment == null) {
        this.commentDialogLastComment = { message: '' }
      }
      this.commentDialogShow = true
    },
    getProc() {
      this.$axios.get('/manufacture/process-template/view', {
        params: { id: this.$route.query.id }
      }).then(r => {
        this.proc = r.data
        this.$nextTick(() => { this.highligthFields() })
      })
    }
  },
  mounted() {
    this.getProc()
  }
}
</script>

<style scoped>
  .hovered >>> .hovered {
    background-color: #cacaca;
    opacity: 90%;
  }
  .hovered:hover >>> .hovered:hover {
    opacity: 65%;
  }
</style>
