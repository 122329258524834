<template>
  <v-dialog
    value="true"
    hide-overlay
    transition="dialog-bottom-transition"
    >
    <v-card>
      <v-toolbar dark color="primary" dense>
        {{title}}
        <v-spacer></v-spacer>
        <v-btn icon small dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4">
        <component
          :is="component"
          :oldValue="oldValue"
          :newValue="newValue"
          :message="message"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SimpleField from '../templates/SimpleField.vue'

export default {
  props: ['title', 'component', 'oldValue', 'newValue', 'message'],
  components: { SimpleField },
}
</script>

<style scoped>
.v-dialog {
  width: auto;
}
</style>
