<template>
  <CommentProcView>
    <template v-slot:title="{ proc }">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          Согласование карты: {{proc.name}}
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:actions="{ proc }">
      <v-card-actions>
        <v-btn color="default" @click="$router.go(-1)">назад</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :disabled="disableAgree(proc)"
          @click="agree(proc)"
          >
          Согласовать
        </v-btn>
        <v-btn
          color="error"
          :disabled="disableCorrect(proc)"
          @click="sendCorrect(proc)"
          >
          Отправить на исправление
        </v-btn>
      </v-card-actions>
    </template>
  </CommentProcView>
</template>

<script>
import CommentProcView from './components/CommentProcView.vue'

export default {
  components: { CommentProcView },
  data: () => ({}),
  methods: {
    disableAgree(proc) {
      let fn = ''
      let c = proc.comments.find(c => {
        if (c.fieldName != fn) {
          fn = c.fieldName
          return c.status != 2
        }
      })
      return !!c
    },
    disableCorrect(proc) {
      let fn = ''
      let c = proc.comments.find(c => {
        if (c.fieldName != fn) {
          fn = c.fieldName
          return c.status == 4
        }
      })
      return !c
    },
    agree(proc) {
      this.$axios.post('manufacture/process-template/agree', {
        id: proc.id,
      }).then(r => {
        this.$router.go(-1)
      }).catch(() => {})
    },
    sendCorrect(proc) {
      this.$axios.post('manufacture/process-template/agree-to-correct', {
        id: proc.id
      }).then(r => {
        this.$router.go(-1)
      }).catch(() => {})
    },
  },
}
</script>
