<template>
  <v-container>
    <table class="table text-center">
      <tr>
        <th style="width: 25%;">СОГЛАСОВАНО</th>
        <th
          class="hovered"
          id="name"
          @click="$emit('click', { fieldName: 'name', fieldTitle: 'Название процесса'})"
          >
            Технологический процесс сварки: {{process.version.name}}
        </th>
        <th style="width: 25%;">УТВЕРЖДАЮ</th>
      </tr>
      <tr>
        <td>{{process.agreeder.post}}</td>
        <th>ОПИСАНИЕ ПОЦЕССА</th>
        <td>{{process.accepter.post}}</td>
      </tr>
      <tr>
        <td>{{process.agreeder.organization}}</td>
        <td
          class="hovered"
          id="about"
          rowspan="3"
          @click="$emit('click', { fieldName: 'about', fieldTitle: 'Описание процесса'})"
          >
          {{process.version.about}}
        </td>
        <td>{{process.accepter.organization}}</td>
      </tr>
      <tr>
        <td>{{concatName(process.agreeder.name)}}</td>
        <td>{{concatName(process.accepter.name)}}</td>
      </tr>
      <tr>
        <td>{{process.agreedAt}}</td>
        <td>{{process.acceptedAt}}</td>
      </tr>
    </table>
    <table class="table">
      <thead>
        <tr>
          <th class="th text-center" colspan="4">
            Карты техпроцесса
          </th>
        </tr>
        <tr>
          <th>Название</th>
          <th>Способ сварки</th>
          <th>Тип элементов</th>
          <th>Тип шва</th>
          <th>Тип соединения</th>
          <th style="width: 15px;"></th>
        </tr>
      </thead>
      <tbody
        class="hovered"
        id="cards"
        @click="$emit('click', { fieldName: 'cards', fieldTitle: 'Карты тех. процесса'})"
        >
        <tr v-for="c in process.version.cards">
          <td>{{c.card.name}}</td>
          <td>{{c.card.weldMethod}}</td>
          <td>{{c.card.elementsType}}</td>
          <td>{{c.card.seamType}}</td>
          <td>{{c.card.jointType}}</td>
          <td>
            <v-btn block tile :to="cardViewUrl(c.card)">
              <v-icon color="info">mdi-eye</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table" v-if="process.version.files.length">
      <thead>
        <tr>
          <th class="th text-center">
            Прикрепленные файлы
          </th>
        </tr>
      </thead>
      <tbody
        class="hovered"
        id="files"
        @click="$emit('click', { fieldName: 'files', fieldTitle: 'Прикрепеленные файлы'})"
        >
        <tr v-for="f in process.version.files">
          <td>
            <a :href="f.url" :download="f.name" target="_blank">
              {{f.name}}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table" style="max-width: 50%;">
      <thead>
        <tr>
          <th class="th text-right pr-1">
            Разработал
          </th>
          <th class="th text-left pl-1">
            {{concatName(process.developer)}}
          </th>
        </tr>
        <tr>
          <th class="th text-right pr-1">
            Дата разработки
          </th>
          <th class="th text-left pl-1">
            {{secToDatetime(process.createdAt)}}
          </th>
        </tr>
      </thead>
    </table>
  </v-container>
</template>

<script>
import { secToDatetime } from '@/libs/time.js'

export default {
  props: ['process'],
  data: () => ({
    secToDatetime,
  }),
  methods: {
    cardViewUrl(card) {
      let m = this.$store.getters.getModule(card.moduleId)
      return m.baseUrl + '/card/view?id=' + card.moduleCardId
    },
    concatName(name) {
      return `${name.firstname} ${name.middlename} ${name.lastname}`
    },
  },
}
</script>

<style>
  .table {
    border-collapse: collapse;
    width: 100%;
  }
  .table td {
    height: 22px;
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
  }
  .table th {
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
    text-align: center;
  }
</style>
