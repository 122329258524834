<template>
  <v-card>
    <div class="px-4 py-2">
      <div v-if="comment.createdBy !== userId">
        {{
          comment.author.firstname + ' ' +
          comment.author.middlename + ' ' +
          comment.author.lastname
        }}
      </div>
      <div v-else class="text-right">Я</div>
      <div :class="[{ 'text-right': comment.createdBy === userId}, 'text--disabled']">
        {{secToDatetime(comment.createdAt)}}
      </div>
    </div>
    <v-divider></v-divider>
    <v-card-text style="white-space: pre;">
      {{ comment.message }}
    </v-card-text>
    <div v-if="showActions">
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" icon @click.stop="acceptComment">
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <v-btn color="error" icon @click.stop="rejectComment">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { secToDatetime } from '@/libs/time.js'

export default {
  props: ['comment'],
  data: () => ({ secToDatetime }),
  computed: {
    userId() { return this.$store.state.user.profile.userId },
    showActions() {
      return this.comment.createdBy !== this.userId && this.comment.status === 1
    }
  },
  methods: {
    acceptComment() {
      this.$axios.post('manufacture/process-template-comment/accept', {
        id: this.comment.id,
      }).then(() => {
        this.comment.status = 2
        this.$emit('statusUpdate')
      }).catch(() => {})
    },
    rejectComment() {
      this.$axios.post('manufacture/process-template-comment/reject', {
        id: this.comment.id,
      }).then(() => {
        this.comment.status = 3
        this.$emit('statusUpdate')
      }).catch(() => {})
    }
  }
}
</script>
